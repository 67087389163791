<template>
  <div class="require">
    <el-tabs
      type="border-card"
      v-model="activeTab">
      <el-tab-pane
        label="待审核"
        name="1"
        lazy>
        <review-table></review-table>
      </el-tab-pane>
      <el-tab-pane
        label="已审核"
        name="2"
        lazy>
        <require-table></require-table>
      </el-tab-pane>
      <el-tab-pane
        label="我的审核"
        name="3"
        lazy>
        <myreview-table></myreview-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import myreviewTable from './components/myreview-table'
import requireTable from './components/require-table'
import reviewTable from './components/review-table'

export default {
  components: {
    requireTable,
    reviewTable,
    myreviewTable
  },
  data () {
    return {
      activeTab: '1'
    }
  }
}
</script>

<style lang="scss" scoped>
  .require {
    width: 980px;
    border: 1px solid #DCDFE6;
    border-radius: 4px;
  }
</style>
