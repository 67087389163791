<template>
  <div class="tabpane_content">
    <div class="content_header">
      <el-input
        style="width:300px;margin-right: 10px;"
        size="small"
        placeholder="请输入需求名称"
        suffix-icon="el-icon-search"
        v-model="searchCondition.keywords"
        @keyup.native.enter="onSearch">
      </el-input>
      <!-- <el-select
        size="small"
        style="width:200px;margin-right: 10px;"
        v-model="searchCondition.logicStatus"
        :clearable="true"
        placeholder="请选择需求状态"
        @change="onSearch">
        <el-option
          v-for="item in requireStatusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select
        size="small"
        style="width:200px;margin-right: 10px;"
        v-model="searchCondition.serviceTypeId"
        :clearable="true"
        placeholder="请选择服务类型"
        @change="onSearch">
        <el-option
          v-for="item in serviceTypeOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select> -->
    </div>
    <div class="content_main">
      <el-table
        v-loading="loading"
        :header-cell-style="headerCellStyle"
        :data="tableData"
        style="width: 100%"
        @row-click="onDetail">
        <el-table-column
          label="序号"
          type="index"
          :index="indexMethod">
        </el-table-column>
        <el-table-column
          prop="serviceTypeName"
          label="服务类型">
        </el-table-column>
        <el-table-column
          prop="title"
          label="需求名称">
        </el-table-column>
        <el-table-column
          prop="contacts"
          label="联系人">
        </el-table-column>
        <el-table-column
          prop="contactMobile"
          label="联系人手机">
        </el-table-column>
        <el-table-column
          prop="operatorAt"
          label="审核时间">
        </el-table-column>
      </el-table>
    </div>
    <div class="content_footer">
      <el-pagination
        hide-on-single-page
        background
        @current-change="pageNumChange"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        layout="prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import globalVar from '@/configs/globalVar'
export default {
  data () {
    return {
      loading: false,
      exportBtnLoading: false,
      // 搜索对象
      searchCondition: {
        keywords: '',
        logicStatus: '',
        serviceTypeId: ''
      },
      serviceTypeOptions: [],
      tableData: [],
      // 分页对象
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  computed: {
    // 表格头
    headerCellStyle () {
      return this.$store.state.headerCellStyle
    },
    requireStatusOptions () {
      return globalVar.REQUIRE_STATUS_OPTIONS.filter(item => {
        return item.value !== globalVar.REQUIRE_STATUS_SUBMITTED
      })
    }
  },
  created () {
    // this.getServiceTypeOptions()
    this.getTableData()
  },
  methods: {
    // 获取列表
    getTableData (pageNum) {
      this.loading = true
      const searchVar = this.getSearchVar()
      api.getMyReviewRequireList({
        ...searchVar,
        pageNum,
        pageSize: this.pagination.pageSize
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    // 获取服务类型
    getServiceTypeOptions () {
      api.getServiceTypeOptions().then(res => {
        if (res.data.code === 0) {
          this.serviceTypeOptions = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    // 获取搜索条件
    getSearchVar () {
      // 把非已提交的需求状态筛选出来
      const statusList = this.globalVar.REQUIRE_STATUS_OPTIONS.filter(item => {
        return item.value !== globalVar.REQUIRE_STATUS_SUBMITTED
      }).map(item => {
        return item.value
      })
      // 再把状态以否号分隔
      const logicStatus = this.searchCondition.logicStatus || statusList.join(',')
      const keywords = this.searchCondition.keywords
      const serviceTypeId = this.searchCondition.serviceTypeId
      return {
        logicStatus,
        keywords,
        serviceTypeId
      }
    },
    // 点击行 进入需求 详情
    onDetail (row) {
      window.open(`/resource-require/service/detail/${row.id}`, '_blank')
    },
    onSearch () {
      this.getTableData(1)
    },
    indexMethod (index) {
      return this.pagination.pageSize * (this.pagination.pageNum - 1) + index + 1
    },
    // 页码变化
    pageNumChange (pageNum) {
      this.getTableData(pageNum)
    }
  }
}
</script>

<style lang="scss" scoped>
  .tabpane_content {
    padding: 15px 25px;
    .content_header {
      display: flex;
      margin-bottom: 20px;
    }
    .content_main {
      margin-bottom: 20px;
    }
    .content_footer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
    }
  }
</style>
