<template>
  <div class="tabpane_content">
    <div class="content_header">
      <el-input
        style="width:300px;margin-right: 10px;"
        size="small"
        placeholder="请输入需求名称"
        suffix-icon="el-icon-search"
        v-model="searchCondition.keywords"
        @keyup.native.enter="onSearch">
      </el-input>
      <el-select
        size="small"
        style="width:200px;margin-right: 10px;"
        v-model="searchCondition.serviceTypeId"
        :clearable="true"
        placeholder="请选择服务类型"
        @change="onSearch">
        <el-option
          v-for="item in serviceTypeOptions"
          :key="item.id"
          :label="item.name"
          :value="item.id">
        </el-option>
      </el-select>
    </div>
    <div class="content_main">
      <el-table
        v-loading="loading"
        :header-cell-style="headerCellStyle"
        :data="tableData"
        style="width: 100%">
         <el-table-column
          label="序号"
          type="index"
          :index="indexMethod">
        </el-table-column>
        <el-table-column
          prop="serviceTypeName"
          label="服务类型">
        </el-table-column>
        <el-table-column
          prop="title"
          label="需求名称">
        </el-table-column>
        <el-table-column
          prop="contacts"
          label="联系人">
        </el-table-column>
        <el-table-column
          prop="contactMobile"
          label="联系人手机">
        </el-table-column>
        <el-table-column
          prop="createdAt"
          label="申请时间">
        </el-table-column>
         <el-table-column
          width="100px"
          label="操作">
          <template slot-scope='scoped'>
            <span
              class="text_color_active tw-cursor-pointer"
              @click.stop="onDetail(scoped.row)">
              审核
            </span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="content_footer">
      <el-pagination
        hide-on-single-page
        background
        @current-change="pageNumChange"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        layout="prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import globalVar from '@/configs/globalVar'

export default {
  data () {
    return {
      loading: false,
      // 搜索对象
      searchCondition: {
        keywords: '',
        logicStatus: globalVar.REQUIRE_STATUS_SUBMITTED,
        serviceTypeId: ''
      },
      serviceTypeOptions: [],
      tableData: [],
      // 分页对象
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  computed: {
    // 表格头
    headerCellStyle () {
      return this.$store.state.headerCellStyle
    }
  },
  mounted () {
    this.getServiceTypeOptions()
    this.getTableData()
  },
  methods: {
    getServiceTypeOptions () {
      api.getServiceTypeOptions().then(res => {
        if (res.data.code === 0) {
          this.serviceTypeOptions = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    getTableData (pageNum) {
      this.loading = true
      api.getReviewRequireList({
        keywords: this.searchCondition.keywords,
        logicStatus: this.searchCondition.logicStatus,
        serviceTypeId: this.searchCondition.serviceTypeId,
        pageNum,
        pageSize: this.pagination.pageSize
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
        this.loading = false
      }).catch(err => {
        this.loading = false
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    onSearch () {
      this.getTableData(1)
    },
    indexMethod (index) {
      return this.pagination.pageSize * (this.pagination.pageNum - 1) + index + 1
    },
    pageNumChange (pageNum) {
      this.getTableData(pageNum)
    },
    onDetail (row) {
      const { href } = this.$router.resolve({
        path: `/resource-require/require/detail-review/${row.id}`
      })
      window.open(href, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
  .tabpane_content {
    padding: 15px 25px;
    .content_header {
      display: flex;
      margin-bottom: 20px;
    }
    .content_main {
      margin-bottom: 20px;
    }
    .content_footer {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 40px;
    }
  }
</style>
